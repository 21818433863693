import React, { useEffect, useState } from 'react';
import '../index.css';
import { Grid } from '@mui/material';
import { Button, Col, message, Row, Statistic, Typography } from 'antd';
import { bidProduct } from '@page/AdminPage/Auction/helpers/request';
import { SUCCESS } from '@utils/baseAPI/HttpStatusCode';
import { formatPrice } from '@contants/formatPrice';
import { contantAuthentication } from '@contants/index';
import { ReactComponent as Vector } from '@assets/images/user/vector.svg';
import AuctionPopup from '../Dialogs/DialogsBildAuction';
import { calculateTimeLeft } from '@contants/formatDayTime';
import DialogsHistoryAuction from '../Dialogs/DialogsHistoryAuction';
import { loadFromLocalStorage } from '@databases/localStorage';
import { constantExchangeRate } from '@contants/index';
import { Link } from 'react-router-dom';
import { formatVNDCurrency } from '@utils/utils';
const { Title, Text } = Typography;

export const ProductDetail = ({
  data,
  refetch,
  isOpenModalAuction,
  setIsOpenModalAuction,
  handleGetDataAuction,
  roleSale,
  customerId,
  customerSelectId
}) => {
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingBid, setIsLoadingBid] = useState(false);
  const [error, setError] = useState('');
  const [price, setPrice] = useState(0);
  const [type, setType] = useState(1);
  const [isOpenModalBuyNow, setIsOpenModalBuyNow] = useState(false);
  const exchangeRateJPY = JSON.parse(
    loadFromLocalStorage(constantExchangeRate.EXCHANGE_RATE)
  )?.bank_sell;
  const [result, setResult] = useState(null);

  const hidePopup = () => setIsOpenModalAuction(false);
  const hidePopUpHistory = () => setIsOpenModalHistory(false);
  const showModalPopupHistory = () => setIsOpenModalHistory(true);
  const openModalAuction = (type) => {
    setType(type);
    if (type === 2) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 1) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 3) {
      setIsOpenModalAuction(true);
      return;
    }
    if (type === 4) {
      setIsOpenModalAuction(true);
      return;
    }
  };

  const roles = localStorage.getItem(contantAuthentication.USER_DATA)
    ? JSON.parse(localStorage.getItem(contantAuthentication.USER_DATA)).roles
    : null;

  const auction = () => {
    if (price > data?.price.value * 174) {
      setError('Giá đấu cần lớn hơn giá hiện tại.');
      return;
    }

    setIsLoading(true);
    bidProduct({
      auc_id: data?.detail.auction_id,
      buy_now: type === 2,
      price: price
    }).then((res) => {
      setIsLoading(false);
      if (res.status === SUCCESS) {
        message.success('Đấu giá thành công').then();
        setIsOpenModalAuction(false);
      }
    });
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(data?.detail.end_date));
  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(data?.detail.end_date));
    }, 1000);

    return () => clearTimeout(timer);
  }, [timeLeft, data?.detail.end_date]);
  const { days, hours, minutes, seconds } = timeLeft;
  useEffect(() => {
    if (data?.price) {
      if (data?.price?.is_bid_win !== undefined) {
        setResult(data);
      }
    }
  });
  useEffect(() => {
    if (result) {
      handleGetDataAuction(result);
    }
  }, [result]);
  let isBuyNow = new Date() > new Date(data?.detail?.end_date);
  let isBidWin = data?.price?.is_bid_win;
  return (
    <div className="product-body">
      <h1 className="product-name">{data?.title.text}</h1>
      <div className="auction-timer">
        <Row gutter={16} align="middle" justify="space-between">
          <Col span={6} push={18}>
            <div className="auction_right_top_title">
              <strong style={{ fontSize: '18px' }}>{data?.auction.number}</strong> Đang đấu giá
            </div>
            <div className="auction_right_bot_titel">
              <a
                rel="noreferrer"
                target="_blank"
                className="btn-link c-pointer"
                onClick={() => setIsOpenModalHistory(true)}>
                Lịch sử đấu giá
              </a>
              <Vector />
            </div>
          </Col>
          <Col span={18} pull={6}>
            <Row align="middle" justify="space-around" textAlign="center">
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{isBuyNow ? '' : days}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Ngày</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{isBuyNow ? '' : hours}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Giờ</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{isBuyNow ? '' : minutes}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Phút</Text>
                </Title>
              </Col>
              <Col>:</Col>
              <Col span={3}>
                <Title level={4}>
                  <Text strong>{isBuyNow ? '' : seconds}</Text>
                </Title>
                <Title level={5} style={{ marginBottom: 0 }}>
                  <Text>Giây</Text>
                </Title>
              </Col>
            </Row>
          </Col>
          {/* <div style={{ padding: '20px', background: '#fff', borderRadius: '8px' }}>
            <div style={{ marginBottom: '16px' }}>
              <span style={{ fontWeight: 'bold' }}>Thời gian đấu giá</span>D ngày : H giờ : m phút :
              s giây
            </div>

            <div>
              <span style={{ fontWeight: 'bold' }}>Lượt đấu giá</span>
              <div style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                <span style={{ marginRight: '8px' }}>80</span>
                <Link href="/auction-history">Lịch sử đấu giá →</Link>
              </div>
            </div>
          </div> */}
        </Row>
      </div>
      <div className="product-body_price">
        {/* {data?.bidding_status === 1 && !roleSale && (
          <Col span={24} style={{ padding: 0 }}>
            <div
              style={{
                backgroundColor: '#E8EBEF',
                width: '100%',
                height: '44px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '16px',
                color: '#F82859',
                fontSize: '14px',
                fontWeight: '500'
              }}>
              {`Bạn đã đặt giá ${formatPrice(
                data?.price?.bid_price
              )} ¥, giá đấu của bạn đã bị vượt`}
            </div>
          </Col>
        )}
        {data?.bidding_status === 2 && !roleSale && (
          <Col span={24} style={{ padding: 0 }}>
            <div
              style={{
                backgroundColor: 'var(--primary-basic-10, #00904A1A)',
                width: '100%',
                height: '44px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '16px',
                color: '#00904A',
                fontSize: '14px',
                fontWeight: '500'
              }}>
              {`Bạn đã đặt giá ${formatPrice(
                data?.price?.bid_price
              )} ¥, giá đấu của bạn đang là cao nhất`}
            </div>
          </Col>
        )} */}
        {data?.price?.label ? (
          <Col span={24} style={{ padding: 0 }}>
            <div
              style={{
                backgroundColor:
                  !isBidWin && isBidWin !== undefined
                    ? 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))'
                    : data?.price?.value > data?.price?.bid_price
                    ? 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))'
                    : 'var(--primary-basic-10, #00904A1A)',
                width: '100%',
                height: '44px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                paddingLeft: '16px',
                color:
                  !isBidWin && isBidWin !== undefined
                    ? '#F82859'
                    : data?.price?.value > data?.price?.bid_price
                    ? '#F82859'
                    : '#00904A',
                fontSize: '14px',
                fontWeight: '500',
                marginBottom: '16px'
              }}>
              {data?.price?.label}
            </div>
          </Col>
        ) : (
          ''
        )}
        {data?.price?.win_price || data?.price?.bid_price || isBidWin !== undefined || isBuyNow ? (
          <>
            <div>
              {isBidWin === undefined && isBuyNow && (
                <div
                  style={{
                    padding: '16px',
                    backgroundColor:
                      !isBuyNow && isBidWin === undefined
                        ? '#fff'
                        : isBuyNow && isBidWin === undefined
                        ? 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))'
                        : isBidWin
                        ? 'var(--primary-basic-10, rgba(0, 144, 74, 0.10))'
                        : 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))',
                    borderRadius: '4px',
                    marginBottom: '16px',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontStyle: 'normal',
                    color:
                      isBuyNow && isBidWin === undefined
                        ? '#F82859'
                        : isBidWin
                        ? '#389e0d'
                        : '#F82859'
                  }}>
                  {isBuyNow ? 'Đấu giá kết thúc' : ''}
                </div>
              )}
              {/* {isBidWin !== undefined && !roleSale && (
                <div
                  style={{
                    padding: '16px',
                    backgroundColor:
                      !isBuyNow && isBidWin === undefined
                        ? '#fff'
                        : isBuyNow && isBidWin === undefined
                        ? 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))'
                        : isBidWin
                        ? 'var(--primary-basic-10, rgba(0, 144, 74, 0.10))'
                        : 'var(--secondary-error-10, rgba(248, 40, 89, 0.10))',
                    borderRadius: '4px',
                    marginBottom: '16px',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontStyle: 'normal',
                    color:
                      isBuyNow && isBidWin === undefined
                        ? '#F82859'
                        : isBidWin
                        ? '#389e0d'
                        : '#F82859'
                  }}>
                  {!isBuyNow && isBidWin === undefined ? '' : data?.price?.label}
                </div>
              )} */}
              {/* Bidding information */}
              <Row gutter={[0, 16]}>
                <Col className="gutter-row" span={24}>
                  {data?.price.win_price !== '' && data?.price.win_price !== undefined && (
                    <>
                      <div className="label-price bold">Giá đấu thắng:</div>
                      <div className="amount-price price-jp bold">
                        <span style={{ color: '#192434', fontSize: '18px' }}>
                          {formatPrice(data?.price.win_price || data?.price?.value)} ¥
                        </span>{' '}
                        (
                        <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                          {data?.price.win_price ? (
                            <span>
                              ≈{formatVNDCurrency(data?.price.win_price * exchangeRateJPY)} đ
                            </span>
                          ) : (
                            <span>0 đ</span>
                          )}
                        </span>
                        )
                      </div>
                    </>
                  )}
                </Col>

                <Col className="gutter-row" span={data?.price?.value > 0 ? 12 : 24}>
                  {data?.price.value !== '' &&
                    data?.price.value !== undefined &&
                    data?.price?.value > 0 && (
                      <>
                        <div className="label-price bold">Giá đấu hiện tại:</div>
                        <div className="amount-price price-jp bold">
                          <span style={{ color: '#192434', fontSize: '18px' }}>
                            {formatPrice(data?.price.value)} ¥
                          </span>{' '}
                          (
                          <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                            {data?.price.value > 0 ? (
                              <span>
                                ≈{formatVNDCurrency(data?.price.value * exchangeRateJPY)} đ
                              </span>
                            ) : (
                              <span>0 đ</span>
                            )}
                          </span>
                          )
                        </div>
                      </>
                    )}
                </Col>
                <Col className="gutter-row" span={12}>
                  {data?.price.bid_price !== '' && data?.price.bid_price !== undefined && (
                    <>
                      <div className="label-price bold">Giá đấu của bạn:</div>
                      <div className="amount-price price-jp bold">
                        <span style={{ color: '#192434', fontSize: '18px' }}>
                          {formatPrice(data?.price.bid_price)} ¥
                        </span>{' '}
                        (
                        <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                          {data?.price.bid_price > 0 ? (
                            <span>
                              ≈{formatVNDCurrency(data?.price.bid_price * exchangeRateJPY)} đ
                            </span>
                          ) : (
                            <span>0 đ</span>
                          )}
                        </span>
                        )
                      </div>
                    </>
                  )}
                </Col>
                <Col className="gutter-row" span={12}>
                  {data?.price.buy_now !== '' &&
                    data?.price.buy_now !== undefined &&
                    data?.price.buy_now > 0 && (
                      <>
                        <div className="label-price bold">Giá mua thẳng:</div>
                        <div className="amount-price price-jp bold" style={{ marginBottom: 15 }}>
                          <span style={{ color: '#192434', fontSize: '18px' }}>
                            {formatPrice(data?.price.buy_now)} ¥
                          </span>{' '}
                          (
                          <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                            {data?.price.buy_now > 0 ? (
                              <span>
                                {' '}
                                ≈ {formatVNDCurrency(data?.price.buy_now * exchangeRateJPY)} đ
                              </span>
                            ) : (
                              <span>0 đ</span>
                            )}
                          </span>
                          )
                        </div>
                      </>
                    )}
                </Col>
              </Row>
            </div>
          </>
        ) : (
          <Row gutter={[24, 24]}>
            <Col className="gutter-row" span={12}>
              {data?.price.value !== '' &&
                data?.price.value !== undefined &&
                data?.price.value > 0 && (
                  <>
                    <div className="label-price bold">Giá đấu hiện tại:</div>
                    <div className="amount-price price-jp bold">
                      <span style={{ color: '#192434', fontSize: '18px' }}>
                        {formatPrice(data?.price.value)} ¥
                      </span>{' '}
                      (
                      <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                        {data?.price.value > 0 ? (
                          <span>≈ {formatVNDCurrency(data?.price?.value * exchangeRateJPY)} đ</span>
                        ) : (
                          <span>0 đ</span>
                        )}
                      </span>
                      )
                    </div>
                  </>
                )}
            </Col>
            {data?.price?.tax > 0 && data?.price?.value !== undefined && (
              <Col className="gutter-row" span={12}>
                <div className="label-price bold">Giá hiện tại (bao gồm thuế):</div>
                <div className="amount-price price-jp bold">
                  <span style={{ color: '#192434', fontSize: '18px' }}>
                    {formatPrice(data?.price?.tax)} ¥
                  </span>{' '}
                  (
                  <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                    {data?.price.value > 0 ? (
                      <span> ≈ {formatVNDCurrency(data?.price?.tax * exchangeRateJPY)} đ</span>
                    ) : (
                      <span>0 đ</span>
                    )}
                  </span>
                  )
                </div>
              </Col>
            )}
            <Col className="gutter-row" span={12}>
              {data?.price.buy_now !== '' &&
                data?.price.buy_now !== undefined &&
                data?.price.buy_now > 0 && (
                  <>
                    <div className="label-price bold">Giá mua thẳng:</div>
                    <div className="amount-price price-jp bold" style={{ marginBottom: 15 }}>
                      <span style={{ color: '#192434', fontSize: '18px' }}>
                        {formatPrice(data?.price.buy_now)} ¥
                      </span>{' '}
                      (
                      <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                        {data?.price.buy_now > 0 ? (
                          <span>
                            ≈ {formatVNDCurrency(data?.price.buy_now * exchangeRateJPY)} đ
                          </span>
                        ) : (
                          <span>0 đ</span>
                        )}
                      </span>
                      )
                    </div>
                  </>
                )}
            </Col>
            {data?.price?.buy_now_tax > 0 && data?.price?.buy_now_tax !== undefined && (
              <Col className="gutter-row" span={12}>
                <div className="label-price bold">Giá mua thẳng (bao gồm thuế):</div>
                <div className="amount-price price-jp bold">
                  <span style={{ color: '#192434', fontSize: '18px' }}>
                    {formatPrice(data?.price?.buy_now_tax)} ¥
                  </span>{' '}
                  (
                  <span className="amount-price price-jp bold" style={{ marginRight: 5 }}>
                    {data?.price.value > 0 ? (
                      <span>
                        ≈ {formatVNDCurrency(data?.price?.buy_now_tax * exchangeRateJPY)} đ
                      </span>
                    ) : (
                      <span>0 đ</span>
                    )}
                  </span>
                  )
                </div>
              </Col>
            )}
          </Row>
        )}
      </div>
      <div>
        <Grid container spacing={3}>
          {!roleSale && roles.indexOf('ACCOUNTING') === -1 && (
            <>
              {data?.price.value !== '' &&
                data?.price.value !== undefined &&
                parseInt(data?.price.buy_now) != parseInt(data?.detail.start_price) && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingRight: '8px' }}>
                    <Button
                      loading={isLoadingBid}
                      onClick={() => openModalAuction(4)}
                      title="Đặt giá đấu ngay"
                      disabled={isBidWin || isBuyNow}
                      className="bid-now"
                      type="primary">
                      Đấu giá
                    </Button>
                  </Grid>
                )}
              {data?.price.buy_now !== '' &&
                data?.price.buy_now !== undefined &&
                parseInt(data?.price.buy_now, 10) != 0 && (
                  <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: '16px' }}>
                    <Button
                      title="Mua thẳng"
                      className="bid-snipe"
                      onClick={() => setIsOpenModalBuyNow(true)}
                      disabled={isBidWin || isBuyNow}
                      type="primary">
                      Mua thẳng
                    </Button>
                  </Grid>
                )}
            </>
          )}
          {roleSale && !roles.includes('ADMIN') && (
            <>
              <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingRight: '8px' }}>
                <Button
                  loading={isLoadingBid}
                  onClick={() => openModalAuction(4)}
                  title="Đặt giá đấu ngay"
                  className="bid-now"
                  disabled={isBidWin !== undefined || isBuyNow}
                  type="primary">
                  Đấu giá hộ
                </Button>
              </Grid>
              {isBuyNow && data?.price?.buy_now !== '' ? (
                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: '16px' }}>
                  <Button
                    title="Mua thẳng"
                    className="bid-snipe"
                    disabled={isBidWin !== undefined || (isBuyNow && data?.price?.buy_now <= 0)}
                    onClick={() => setIsOpenModalBuyNow(true)}
                    type="primary">
                    Mua thẳng hộ
                  </Button>
                </Grid>
              ) : data?.price?.buy_now !== 0 ? (
                <Grid item lg={6} md={6} sm={6} xs={6} style={{ paddingLeft: '16px' }}>
                  <Button
                    title="Mua thẳng"
                    className="bid-snipe"
                    disabled={isBidWin !== undefined || (isBuyNow && data?.price?.buy_now <= 0)}
                    onClick={() => setIsOpenModalBuyNow(true)}
                    type="primary">
                    Mua thẳng hộ
                  </Button>
                </Grid>
              ) : (
                ''
              )}
            </>
          )}
        </Grid>
      </div>
      {isOpenModalAuction && (
        <AuctionPopup
          visible={isOpenModalAuction}
          onCancel={hidePopup}
          type={type}
          remainingTime={timeLeft}
          data={data}
          refetch={refetch}
          handleGetDataAuction={handleGetDataAuction}
          customerId={customerId}
          roleSale={roleSale}
          customerSelectId={customerSelectId}
        />
      )}
      <AuctionPopup
        visible={isOpenModalBuyNow}
        onCancel={() => setIsOpenModalBuyNow(false)}
        type={2}
        remainingTime={timeLeft}
        data={data}
        refetch={refetch}
        handleGetDataAuction={handleGetDataAuction}
        customerId={customerId}
        roleSale={roleSale}
      />
      {isOpenModalHistory && (
        <DialogsHistoryAuction
          histories={[]}
          onCancel={hidePopUpHistory}
          visible={isOpenModalHistory}
          data={data}
          customerId={customerId}
          roleSale={roleSale}
        />
      )}
    </div>
  );
};
