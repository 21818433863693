import React, { useState } from 'react';
import rootApi from '@utils/baseAPI/WrapperApi';
import { Button, Modal, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import './DialogsHistory.css'; // Import the CSS file
import * as moment from 'moment';
const columns = ({ refetch }) => {
  return [
    {
      title: 'Người đấu giá',
      dataIndex: 'auction_account_name',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.username}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Thời gian đặt',
      dataIndex: 'time',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {moment(d?.time).format('DD-MM-YYYY HH:mm')}
        </Typography>
      ),
      width: 220
    },
    {
      title: 'Giá đặt',
      dataIndex: 'price',
      render: (_, d) => (
        <Typography style={{ fontSize: '14px', fontWeight: 500, color: '#00904A' }}>
          {d?.price}
        </Typography>
      ),
      width: 220
    }
  ];
};
const DialogsHistoryAuction = ({
  histories,
  onCancel,
  visible,
  data: product,
  roleSale,
  customerId
}) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: stepPrice,
    isLoading,
    refetch
  } = useQuery(['get-step-price'], () =>
    rootApi.get(`/crawls/step-price`, {
      params: {
        product_id: product?.detail?.auction_id
      }
    })
  );
  const stepPriceData = stepPrice?.data?.data || [];
  return (
    <div>
      <Modal
        title="Lịch sử đấu giá sản phẩm"
        visible={visible}
        onCancel={onCancel}
        footer={[
          <Button
            key="back"
            style={{
              height: '36px',
              backgroundColor: '#E8EBEF',
              color: '#192434',
              fontSize: '14px',
              fontWeight: '500',
              borderRadius: '4px'
            }}
            onClick={onCancel}>
            Đóng
          </Button>
        ]}
        width={720}
        style={{
          paddingLeft: '16px',
          paddingRight: '16px',
          cursor: 'pointer'
        }}>
        <Table
          bordered
          size="small"
          loading={isLoading}
          dataSource={stepPriceData || []}
          columns={columns({ refetch })}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`
          }}
          style={{}}
          rowKey={(record) => record.id}
          className="custom-table" // Add a custom class for additional styling
        />
      </Modal>
    </div>
  );
};

export default DialogsHistoryAuction;
